import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import adesanyam from '../../assets/images/departments/microbio/adesanyam.png';
import adobi from '../../assets/images/departments/microbio/adobi.png';
import azuka from '../../assets/images/departments/microbio/azuka.png';
import aikhomu from '../../assets/images/departments/microbio/aikhomu.png';
import enugwulike from '../../assets/images/departments/microbio/enugwulike.png';
import folakee from '../../assets/images/departments/microbio/folakee.png';
import iyabode from '../../assets/images/departments/microbio/iyabode.png';
import izegaegbe from '../../assets/images/departments/microbio/izegaegbe.png';
import justina from '../../assets/images/departments/microbio/justina.png';
import ogunsola from '../../assets/images/departments/microbio/ogunsola.png';
import osisioma from '../../assets/images/departments/microbio/osisioma.png';
import oyinlola from '../../assets/images/departments/microbio/oyinlola.png';
import philip from '../../assets/images/departments/microbio/philip.png';
import rita from '../../assets/images/departments/microbio/rita.png';
import stella from '../../assets/images/departments/microbio/stella.png';

const Microbiology = () => {
  const sub = [
    {
      name: 'Medical Mycology',
    },
    {
      name: 'Medical Parasitology',
    },
    {
      name: 'Bacteriology',
    },
    {
      name: 'Mycobacterium (TB and others)',
    },

    {
      name: 'Immunology and Serology.',
    },
    {
      name: 'Virology',
    },
    {
      name: 'Infection Prevention and Control',
    },
    {
      name: 'Antimicrobial stewardship program',
    },
  ];

  const services = [
    {
      name:
        'Automated Bacteria / Yeast identification and Antimicrobial Susceptibility Testing (VITEK- 2 Compact)',
    },
    {
      name: 'Automated Blood Culture System (Bact-Alert and Bactec',
    },
    {
      name: 'Anaerobic culture',
    },
    {
      name: 'Microscopy Culture and Sensitivity for all specimen types',
    },

    {
      name:
        'Mycology Culture and Serology (Aspergillus IgG and Aspergillus Galactomannan, Histoplasma GM, Cryptococcal antigen testing(CrAg))',
    },
    {
      name: 'Seminal fluid analysis',
    },
    {
      name: 'Stool Ova and Microscopy',
    },
    {
      name: 'deIntification of  Intestinal and Blood Parasites',
    },
    {
      name: 'Procalcitonin',
    },
    {
      name: 'Mycobacteriology(M.tuberculosis and leprae)',
    },
    {
      name: 'GenXpert',
    },
    {
      name:
        'Infection Prevention and Control Services and Antimicrobial stewardship.',
    },
  ];

  return (
    <Layout pageTitle='Department of Medical Microbiology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Medical Microbiology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Medical Microbiology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Department of Medical Microbiology and Parasitology,
                        Lagos University Teaching Hospital is a foremost
                        tertiary diagnostic and research laboratory providing
                        excellent services based on international best
                        practices. We are a team of professionally skilled and
                        motivated staff providing excellent services using
                        appropriate technology in an environmentally friendly
                        manner to render accurate, reliable and quality clinical
                        diagnostic services in a timely fashion team members
                        guided in the conduct of their official functions and
                        dealings by established values.
                        <br />
                        CONTACT US: Medical Microbiology and Parasitology
                        Department +2348171570142
                        <h2 className='course-details__title'>
                          Sub-Specialities
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {sub.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rita} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oladele Rita Okeoghene </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            MBBS, MSc, FMCPath, PhD, FECMM
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Consultant Clinical Microbiologist(Head Of
                            Department)
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical Mycology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunsola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Prof. F. T. Ogunsola </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            FMCPath, FWACP, PhD, FAS
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Consultant Clinical Microbiologist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Infection Prevention & Control speacialist
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyinlola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Prof. Oyinlola Odunyebo</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            MBBS, MD, FMCPath
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Consultant Clinical Microbiologist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Antimicrobial stewardship
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={philip} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Dr. Philip Oshun</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            MBChB, MPH, MSc, FMCPath
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Consultant Clinical Microbiologist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Virology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={stella} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Dr Chioma Stella Osuagwu</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            MBBS, MPH, MSc Medical Microbiology, FMCPath
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Consultant Clinical Microbiogist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            parasitologist and Infection prevention infection
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={folakee} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Peters Rebecca Folake</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            M.Sc (Medical Microbiology, FMLSCN (Medical
                            Microbiology), PgD (Environmental
                            Microbiology),Epidemiology(Cert), Laboratory
                            Management (Cert.) <br />
                            Certificates: Project Management, M&E., L.M.I.H.
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Deputy Director, Laboratory Services
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical Mycology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adobi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Dike Adobi Ezioma </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            FMLSCN, AMLSCN, M.SC, P.GD, Certs (P.M., L.M.I.H.,
                            HSE 1, 2 & 3)
                            <br />
                            <span className='detail-head'>Designation: </span>
                            ASSISTANT DIRECTOR (LABORATORY SERVICES)
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            MICROBIOLOGY
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={iyabode} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Adekunle-Salami Iyabode Mopelola </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            FMLSCN AMLSCN M.Sc B.Sc
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Scientist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Parasitology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={aikhomu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Aikhomu Victoria A</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            B.Sc Microbiology AMLSN, FMLSN
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Scientist.
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Parasitology, Medical Microbiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={azuka} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Ike Azuka Anthonia</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            B.Sc Applied Microbiology M.Sc Medical Parasitology
                            Associate Medical Microbiology (MLSCN); NiNAS
                            Assessor QMS Mentors MLSCN
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Scientist.
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical Lab. Management, Bio-Safety & Bio-Security
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={izegaegbe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Izegaegbe Samuel O.</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            BMLS, AIMLS, PMI, Cert. Epid.
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Assistant Director Medical Laboratory Services
                            (Microbiology)
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical microbiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={justina} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Makinwa Justina Olajumoke</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            B.Sc Microbiology (UNILAG) AIMLSCN in Virology
                            (FCVMLT, Vom) FMLSCN (LUTH) Health Safety and
                            Environment (ISPON)
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Scientist)
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical microbiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osisioma} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Osisioma Joy E.</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            HND, AIMLS
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Scientist)
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Medical microbiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={enugwulike} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Enugwulike Chidiebere</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            AIMLS,BSc
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Principal Medical Laboratory Scientist
                            <br />
                            <span className='detail-head'>Specialty: </span>
                            Parasitology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adesanyam} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Adesanya Mercy Adenike</h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            Medical Laboratory Technician Certificate: Medical
                            Laboratory Technician
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Chief Medical Laboratory Technician
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:microparasitology@luth.gov.ng'>                        
                        microparasitology@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Microbiology;
